<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters>
            <v-col >
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" @click="showDetails(1,true)" class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <v-icon large left :color="$store.getters.getColorPalette().background2ColorCode">mdi-gas-station</v-icon>Scope 1 Emission
                        <v-spacer></v-spacer>
                        <v-icon large left :color="$store.getters.getColorPalette().background2ColorCode">mdi-solar-power</v-icon>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8" align-self="center">
                                <h2>{{ scope1_data.toFixed(2) }}</h2><h3>{{ $store.getters.getTextMap().kgco2 }}</h3>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col align-item="right">
                                <div class="trend-visualization">
                                    <v-progress-circular
                                        :size="90"
                                        :width="20"
                                        :value="Math.abs(scope1_data)"
                                        :color="scope1_data >= 0 ? '#FF8A80' : '#69F0AE'"
                                    >
                                        <div class="trend-indicator" :class="{ 'positive': scope1_data >= 0, 'negative': scope1_data < 0 }">
                                            <v-icon large>{{ scope1_data >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
                                        </div>
                                    </v-progress-circular>
                                </div>
                            </v-col> 
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col class="mx-2">
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" @click="showDetails(1,true)" class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <v-icon large left :color="$store.getters.getColorPalette().background2ColorCode">mdi-transmission-tower</v-icon>Scope 2 Emission
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8" align-self="center">
                                <h2>{{ scope2_data.toFixed(2) }}</h2><h3>{{ $store.getters.getTextMap().kgco2 }}</h3>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col align-item="right">
                                <div class="trend-visualization">
                                    <v-progress-circular
                                        :size="90"
                                        :width="20"
                                        :value="Math.abs(scope2_data)"
                                        :color="scope2_data >= 0 ? '#FF8A80' : '#69F0AE'"
                                    >
                                        <div class="trend-indicator" :class="{ 'positive': scope2_data >= 0, 'negative': scope2_data < 0 }">
                                            <v-icon large>{{ scope2_data >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
                                        </div>
                                    </v-progress-circular>
                                </div>
                            </v-col> 
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col>
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" @click="showDetails(1,true)" class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                    <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
                        <v-icon large left :color="$store.getters.getColorPalette().background2ColorCode">mdi-truck-outline</v-icon>Scope 3 Emission
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-subtitle>
                        <v-row dense>
                            <v-col cols="8" align-self="center">
                                <h2>{{ scope3_data.toFixed(2) }}</h2><h3>{{ $store.getters.getTextMap().kgco2 }}</h3>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col align-item="right">
                                <div class="trend-visualization">
                                    <v-progress-circular
                                        :size="90"
                                        :width="20"
                                        :value="Math.abs(scope3_data)"
                                        :color="scope3_data >= 0 ? '#FF8A80' : '#69F0AE'"
                                    >
                                        <div class="trend-indicator" :class="{ 'positive': scope3_data >= 0, 'negative': scope3_data < 0 }">
                                            <v-icon large>{{ scope3_data >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
                                        </div>
                                    </v-progress-circular>
                                </div>
                            </v-col> 
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment'
import axios from 'axios'
export default {
    name:'ESGInsightsByScope',
    props:['timeframe'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            showDismissibleAlert:false,
            loading:false,
            info:'',
            scope1_data:0,
            scope2_data:0,
            scope3_data:0,
            dbData:[],  
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            let from_date=null
            if(this.timeframe =='month' || this.timeframe =='year'){
                switch(this.timeframe){
                    case 'month':
                        from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                        break;
                    case 'year':
                        from_date=moment().subtract(1, 'days').startOf('year').format('YYYYMMDD')  
                        break;
                }
            }else{
                from_date=moment().subtract(this.timeframe,'days').format('YYYYMMDD')
            }
            axios.post(this.$store.state.api+'getESGScopeDataBetweenDate',{from_date},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.dbData=response.data.data
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                    this.loading=false
                }
            }).catch(err=>{
                console.error(err)
                this.info=err
                this.showDismissibleAlert=true
                this.loading=false
            })
        },
        createScopeWiseData(){
            this.scope1_data=0
            this.scope2_data=0
            this.scope3_data=0
            for(let i of this.dbData){
                switch( i.scope){
                    case '1':
                        this.scope1_data=this.scope1_data + i.tco2
                        break;
                    case '2':
                        this.scope2_data=this.scope2_data + i.tco2
                        break;
                    case '3':
                        this.scope3_data=this.scope3_data + i.tco2
                        break;
                }
            }
        },
    },
    watch:{
        dbData: function (){
            this.createScopeWiseData()
        },
        timeframe: function (){
            this.getData()
        }
    }
}
</script>