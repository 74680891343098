<template>
    <v-container fluid >
        <ESGPanel/>
    </v-container>
</template>
<script>
import ESGPanel from '@/components/panels/ESGPanel'
export default {
    name:'ESG',
    components:{
        ESGPanel,
  },
}
</script>