<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col cols="3">
                <v-card-title :style="{color:$store.getters.getColorPalette().accentCode,display:'flex', justifyContent:'center'}"  :dark="$store.getters.getColorPalette().isDark" class="sub-heading pb-2"><v-icon :color="$store.getters.getColorPalette().inputtextColor" class="mr-2">mdi-filter-outline</v-icon>{{$store.getters.getTextMap().filters}}</v-card-title>
            </v-col>
            <v-col class="mt-2">
                <v-select
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-model="scope"
                    :items="scopeOptions"
                    :label="$store.getters.getTextMap().scope_label"
                    dense
                    outlined
                    item-text="label"
                    item-value="value"
                ></v-select>  
            </v-col>
            <v-col class="mt-2">
                <v-select
                    :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-model="groupByKey"
                    :items="groupByOptions"
                    :label="$store.getters.getTextMap().group_by"
                    dense
                    outlined
                    item-text="label"
                    item-value="value"
                    ></v-select>  
            </v-col>
        </v-row>
        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
        <v-row>
            <v-col>
                <div class="my-1">
                    <v-row  no-gutters >
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn>
                                <v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </div>
                <div ref="vis"></div>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="dbData && dbData.length>0 && tableData && tableData.length>0" class="mt-2">
            <v-col>
                <v-card flat :dark="$store.getters.getColorPalette().isDark">
                    <v-data-table
                        :headers="headers"
                        :items="tableData"
                        item-key="date"
                        :loading="loading"
                        :dark="$store.getters.getColorPalette().isDark"
                        loading-text="Loading... Please wait"
                        :style="{background: $store.getters.getColorPalette().background2ColorCode}"
                    >
                        <template v-slot:top >
                            <v-toolbar flat :style="{background: $store.getters.getColorPalette().background2ColorCode}">
                               {{` ESG Data Grouped by ${groupByKeyLabelMap[groupByKey]}`}}
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-icon @click="fetchData">mdi-refresh</v-icon>
                            </v-toolbar>
                            <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col>
                <v-card :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" flat outlined class="px-2 mb-2">
                    <v-card-title :style="{display:'flex', justifyContent:'center'}" :dark="$store.getters.getColorPalette().isDark" class="sub-heading"><v-icon class="mr-2">mdi-database-remove-outline</v-icon>{{$store.getters.getTextMap().no_data_available}}</v-card-title>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment'
import axios from 'axios'
import Plotly from 'plotly.js'
export default {
    name:'ESGScopeWiseDetails',
    props:['timeframe'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            info:'',
            loading:false,
            showDismissibleAlert:false,
            scope:"1",
            groupByKey:"source",
            tableData:[],
            headers :[],
            headerList:[],
            graphType:'bar',
            scopeOptions:[
                {label:this.$store.getters.getTextMap().esg_scope1_label,value:"1"},
                {label:this.$store.getters.getTextMap().esg_scope2_label,value:"2"},
                {label:this.$store.getters.getTextMap().esg_scope3_label,value:"3"},
            ],
            groupByOptions:[
                {label:this.$store.getters.getTextMap().source,value:"source"},
                {label:this.$store.getters.getTextMap().name,value:"name"},
                {label:this.$store.getters.getTextMap().type,value:"type"},
            ],
            groupByKeyLabelMap:{
                "source":"Source",
                "name":"Name",
                "type":"Type"
            },
            dbData:[],
            graphData:{},
            layout : {
                barmode: 'group',
                font:{
                    color:this.$store.getters.getColorPalette().accentCode,
                    },
                plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                showlegend:true,
                autosize: true,
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },       
                yaxis: {
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            },
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        chartInit(){
            if(this.headerList && this.headerList.length>0){
                this.graphData={}
                this.layout['title']= ` ESG Data for Scope ${this.scope} Grouped by ${this.groupByKeyLabelMap[this.groupByKey]}`
                let type=this.graphType==1?'line':'bar'
                for(let i of this.headerList ){
                    this.graphData[i]={
                    x: [],
                    y: [],
                    name:i,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        createTable(){
            if(this.dbData && this.dbData.length>0){
                let headerTemp=new Set([])
                this.tableData=[]
                this.headers = []
                this.headers.push({text: 'Date', value: 'date', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().backgroundColorCode })
                let temp=this.dbData.reduce((prev,current)=>{
                    if(!(current.date in prev)){
                        prev[current.date]={
                            date:moment(current.date,'YYYY-MM-DD').format('YYYY-MM-DD')
                        }
                    }
                    prev[current.date][current[this.groupByKey]]=current['data']
                    return prev
                },{})
                this.tableData=Object.values(temp)
                for(let i of this.dbData){
                    headerTemp.add(i[this.groupByKey])
                }
                this.headerList=[...headerTemp]
                for(let h of this.headerList){
                    this.headers.push({text:h, value:h,class: "text-subtitle-1 text-capitalize  font-weight-black "+this.$store.getters.getColorPalette().backgroundColorCode})
                }
            }
        },
        createVisualisation(){
            if(this.dbData && this.dbData.length>0 && this.tableData && this.tableData.length>0 && this.headerList && this.headerList.length>0){
                this.chartInit()
                for(let i of this.dbData){
                if(!(i[this.groupByKey] in this.graphData)){
                  let type=this.graphType==1?'line':'bar'
                  this.graphData[i[this.groupByKey]]={
                  x: [],
                  y: [],
                  name:i[this.groupByKey],
                  type: type,
                  opacity:0.9,
                  marker: {
                    line: {
                      color: this.$store.getters.getColorPalette().accentCode,
                      width: 1
                      },
                    }
                }
                }
                this.graphData[i[this.groupByKey]]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                this.graphData[i[this.groupByKey]]['y'].push(i.data)
              }
              if(this.dbData && this.dbData.length>0 && this.tableData && this.tableData.length>0 && this.headerList && this.headerList.length>0 && this.graphData && Object.keys(this.graphData).length>0){
                  Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, {displaylogo: false});
              }
            }
        },
        fetchData(){
            this.headerList=[]
            this.graphData={}
            this.dbData=[]
            this.tableData=[]
            this.loading=true
            let from_date=null
            if(this.timeframe =='month' || this.timeframe =='year'){
                switch(this.timeframe){
                    case 'month':
                        from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
                        break;
                    case 'year':
                        from_date=moment().subtract(1, 'days').startOf('year').format('YYYYMMDD')  
                        break;
                }
            }else{
                from_date=moment().subtract(this.timeframe,'days').format('YYYYMMDD')
            }
            axios.post(this.$store.state.api+'getESGDataBetweenDateByScope',{from_date,scope:this.scope},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status==='success'){
                    this.loading=false
                    if(response.data && response.data.data && response.data.data.length>0){
                        this.dbData=response.data.data
                    }else{
                        this.info="No Data Available"
                        this.showDismissibleAlert=true
                        this.loading=false
                    }
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                    this.loading=false
                }
            }).catch(err=>{
                console.error(err)
                this.info=err
                this.showDismissibleAlert=true
                this.loading=false
            })
        },
    },
    watch:{
        timeframe(){
            this.fetchData()
        },
        dbData(){
            this.createTable()
        },
        scope(){
            this.fetchData()
        },
        groupByKey(){
            if(this.dbData && this.dbData.length>0 && this.tableData && this.tableData.length>0){
                this.createTable()
            }
        },
        headerList(){
            // if(this.dbData && this.dbData.length>0 && this.tableData && this.tableData.length>0 && this.headerList && this.headerList.length>0){
                this.createVisualisation()
            // }
        },
        graphType: function (){
            if(this.headerList && this.headerList.length>0){
                this.createVisualisation()
            }
        }
    }
}
</script>